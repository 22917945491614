import * as React from 'react';

import { Router } from '@reach/router';
import Page from '../components/common/Page';
import Container from '../components/common/Container';
import IndexLayout from '../components/common/Layout';
import LanguageBuddyChatPage from '../components/subpages/LanguageBuddyChatPage';
import { Route } from '../components/Route';

const LanguageBuddyIndexPage = () => {
  return (
    <IndexLayout>
      <Page>
        <Container>
          <Router>
            <Route
              path="/languageBuddyChat/:id"
              component={LanguageBuddyChatPage}
            />
          </Router>
        </Container>
      </Page>
    </IndexLayout>
  );
};

export default LanguageBuddyIndexPage;
