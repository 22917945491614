import React, { FC } from 'react';
import gql from 'graphql-tag';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';

import { Link } from 'gatsby';
import { LanguageBuddyChatFragmentFragment } from '../generated/graphql';
import { formatDate } from '../util';

export const languageBuddyChatFragment = gql`
  fragment languageBuddyChatFragment on LanguageBuddyChat {
    id
    created
    isReported
    users {
      id
      name
    }
    messages {
      id
      user {
        id
        name
      }
      created
      content
    }
  }
`;

export const LanguageBuddyChat: FC<LanguageBuddyChatFragmentFragment> = chat => {
  const getMessageColor = (id: string | undefined) => {
    if (id === chat.users[0].id) return 'success.main';
    if (id === chat.users[1].id) return 'info.main';
    return '#EEE';
  };

  const getUserLink = (
    user: LanguageBuddyChatFragmentFragment['messages'][0]['user'],
  ) => {
    if (!user || !chat.users.map(({ id }) => id).includes(user?.id))
      return <span>bot</span>;
    return <Link to={`/user/${user.id}`}>{user.name}</Link>;
  };

  return (
    <Box component={Paper} p={2}>
      <Typography variant="h4">
        Chat between{' '}
        <Link to={`/user/${chat.users[0].id}`}>{chat.users[0].name}</Link> and{' '}
        <Link to={`/user/${chat.users[1].id}`}>{chat.users[1].name}</Link>
      </Typography>
      <Box paddingY={2}>{formatDate(chat.created)}</Box>
      {chat.isReported && (
        <Box color="red" display="flex" alignItems="center" fontSize={20}>
          <Icon>warning</Icon> This chat has been reported!
        </Box>
      )}

      {chat.messages.map(message => (
        <Box
          m={2}
          p={1}
          component={Paper}
          bgcolor="#EEE"
          key={message.id}
          position="relative"
        >
          <Box
            bgcolor={getMessageColor(message.user?.id)}
            width="3px"
            height="100%"
            position="absolute"
            left={0}
            top={0}
          />

          {message.content}
          <Box
            display="flex"
            justifyContent="space-between"
            paddingTop={2}
            fontSize={10}
          >
            {getUserLink(message.user)}
            {formatDate(message.created)}
          </Box>
        </Box>
      ))}
    </Box>
  );
};
