import * as React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import Container from '../common/Container';
import { LanguageBuddyChatQuery } from '../../generated/graphql';
import {
  LanguageBuddyChat,
  languageBuddyChatFragment,
} from '../LanguageBuddyChat';
import { GraphQLErrorDisplay } from '../GraphQLErrorDisplay';

const languageBuddyChatQuery = gql`
  query LanguageBuddyChat($id: ID!) {
    node(id: $id) {
      ... on LanguageBuddyChat {
        ...languageBuddyChatFragment
      }
    }
  }
  ${languageBuddyChatFragment}
`;

interface LanguageBuddyChatPageProps {
  id: string;
}

const LanguageBuddyChatPage = ({ id }: LanguageBuddyChatPageProps) => {
  const { loading, error, data } = useQuery<LanguageBuddyChatQuery>(
    languageBuddyChatQuery,
    { variables: { id } },
  );

  if (loading) return 'Loading...';
  if (error) return <GraphQLErrorDisplay error={error} />;

  if (!data || !data.node || data.node.__typename !== 'LanguageBuddyChat')
    return 'Not found :(';

  return (
    <Container>
      <LanguageBuddyChat {...data.node} />
    </Container>
  );
};

export default LanguageBuddyChatPage;
